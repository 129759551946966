@import "fonts.scss";
// @import "mobile.scss";
@import "normalize.scss";
@import "nav.scss";
@import "components.scss";
@import "scroll.scss";
@import "logo.scss";
@import "+tablet.scss";
@import "+land-mobile.scss";
@import "+port-mobile.scss";


$body-background: rgb(9, 13, 17);
$primaryColor: #e45d0f;
$secondaryColor: #022ac7;
$bg-grad: linear-gradient(#000000, #00000000);

body {
  margin: 0;
  font-family: -apple-system, 'Poppins', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  will-change: 'background';
  background-color: $body-background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pn-logo {
  min-height: 100px;
  font-size: 100px;
  
  & span {
    opacity: .8;
    font-size:1.2em
  }

  & .first, & .second {
    transform: translate3d(0px, 0px, -30px);
  }
  & .first {
    transform-origin: bottom;
    transform:translate3d(0px, 0px, -30px) rotate3d(0, 10, 1, 25deg);
  }
  & .second {
    transform-origin: top;
    transform:translate3d(0px, 0px, -30px) rotate3d(1, 0, 0, 90deg );
  }
}
.bordered-box {
  transform:
    scale(0.75)
    rotateY(-30deg)
    rotateX(45deg)
    translateZ(4.5rem);
  transform-origin: 50% 100%;
  transform-style: preserve-3d;
  box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.25);
  transition: 0.6s ease transform;

  &:hover {
    transform: scale(1);
  }

  &::before {
    transform: translateZ(4rem);
    &:hover {
      transform: translateZ(0);
    }
  }

  &::after {
    transform: translateZ(-4rem);
    &:hover {
      transform: translateZ(-1px);
    }
  }
}

div.non {
  transform: rotateX(51deg) rotateZ(43deg);
  transform-style: preserve-3d;
  border-radius: 32px;
  box-shadow:
    1px 1px 0 1px #f9f9fb,
    -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    28px 28px 28px 0 rgba(34, 33, 81, 0.25);
  transition:
    .4s ease-in-out transform,
    .4s ease-in-out box-shadow;

  &:hover {
    transform:
      translate3d(0px, -16px, 0px)
      rotateX(51deg)
      rotateZ(43deg);
    box-shadow:
      1px 1px 0 1px #f9f9fb,
      -1px 0 28px 0 rgba(34, 33, 81, 0.01),
      54px 54px 28px -10px rgba(34, 33, 81, 0.15);
  }
}

svg:hover path{
  filter: url(#inset-shadow);
}