.logo-puunukk {
  display: block;
  width: 11vw;
  height: auto;
  max-width: 116px;
  margin-top: auto;
}
// .logo-puunukk svg {
//   fill: #90caf9;
//   &:hover,
//   &:nth-child(2) {
//     filter: 'drop-shadow( 3px 5px 2px rgb(0 0 0 / 0.4) )';
//   }
// }