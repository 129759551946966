@media screen and (max-width: 479px) {
  .puunukk_cv {
    display: block;
    letter-spacing: -2vw;
  }

  .tag1.lovely {
    display: none;
  }

  .p-body1.end {
    font-size: 3.6vw;
    line-height: 6.5vw;
  }

  .bg-cont {
    top: 28vw;
  }

  .header-tags {
    margin-top: 9vw;
  }

  .cv-cont-anima {
    width: 90vw;
    margin-top: 100px;
  }

  .cv-cont {
    width: 90vw;
    margin-top: 100px;
  }

  .noise-end {
    width: 90vw;
  }

  .jm-cv-cont {
    margin-bottom: 16vw;
  }

  .line-cont {
    margin-top: 0px;
  }

  .dark-effect-cont {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .logo-puunukk {
    width: 26vw;
  }

  .open-menu-jm {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: #131518;
  }

  .active-btn-social {
    height: 84px;
    border-radius: 5px;
  }

  .this-h {
    margin-left: 0px;
    line-height: 5.7vh;
  }

  .blue-circle1 {
    width: 160vw;
    height: 180vw;
    // margin-top: -78vw;
    margin-left: -56.1vw;
  }

  .container {
    padding-top: 6px;
  }

  .dark1 {
    height: 16vh;
  }

  .puunukk_cv-2 {
    display: block;
    margin-bottom: -3.2vw;
    letter-spacing: -2vw;
    &.nukk {
      margin-left: 10.3vw;
    }
  }

  .tag1-2 {
    opacity: 1;
    font-size: 3vw;
    line-height: 3vw;
  }

  .tag1-2.lovely {
    display: none;
  }

  .p-body1-2 {
    opacity: 1;
    font-size: 3.5vw;
    line-height: 6vw;
    letter-spacing: 0.4px;
  }

  .p-body1-2.end {
    font-size: 3.6vw;
    line-height: 6.5vw;
  }

  .cv-cont-2 {
    margin-top: 29vw;
    padding: 11vw 5vw;
  }

  .link-big-social-2 {
    margin-left: 0px;
  }

  .noise-end-2 {
    width: 90vw;
  }

  .heading-2 {
    font-size: 5vw;
  }

  .this-h-2 {
    margin-top: 17vw;
    margin-bottom: 20vw;
    font-size: 12vw;
    line-height: 10vw;
  }

  .finally-h-2 {
    margin-bottom: 19vw;
    font-size: 5vw;
  }

  .jm-title-email {
    margin-top: 25.5vw;
  }
}
