@media screen and (max-width: 991px) {
  .tab2 {
    top: 91.2vw;
    width: 52vw;
    margin-left: 5vw;
  }

  .puunukk_cv {
    margin-top: 100px;
    margin-bottom: -20px;
    margin-left: -97px;
    font-size: 200px;
    letter-spacing: -11px;
  }

  .h-footer {
    font-size: 12vw;
    line-height: 11vw;
  }

  .moment-text {
    margin-bottom: 10px;
    font-size: 9vw;
    line-height: 9vw;
    letter-spacing: -0.1vw;
  }

  .navbar {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .section {
    display: none;
  }

  .x-tab {
    display: none;
  }

  .tag1.right {
    display: none;
  }

  .tag1.lovely {
    margin-top: 174px;
    margin-left: -21px;
  }

  .p-body1 {
    font-size: 13px;
    line-height: 23px;
  }

  .p-body1.end {
    width: 100%;
  }

  .cont-lines {
    display: none;
  }

  .bg-cont {
    left: 5vw;
    top: 20vw;
    width: 90vw;
    margin-top: 0px;
    margin-left: 0px;
  }

  .header-tags {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 46px;
    margin-left: 154px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .cv-cont-anima {
    display: none;
    margin-top: 142px;
    margin-bottom: 10vh;
    padding: 50px 50px 10vh;
  }

  .harder-img {
    top: 7.7vw;
    width: 60vw;
  }

  .friend-lottie {
    top: 79.4vw;
    display: none;
    width: 59vw;
    margin-right: -57vw;
  }

  .cv-cont {
    margin-top: 142px;
    margin-bottom: 10vh;
    padding: 50px 50px 10vh;
  }

  .link-big-social {
    padding-top: 60px;
    padding-bottom: 39px;
    font-size: 90px;
  }

  .text-end2 {
    font-size: 3vw;
    line-height: 4vw;
  }

  .icon-jm1 {
    width: 39px;
  }

  .text-user {
    top: 23.3vw;
    margin-right: -34.9vw;
    font-size: 7vw;
    line-height: 8vw;
    letter-spacing: 0.2vw;
  }

  .blue-circle2 {
    display: none;
  }

  .social-cont {
    margin-top: 0vh;
  }

  .end {
    display: none;
  }

  .end.noise {
    display: none;
  }

  .dark2 {
    display: none;
  }

  .link-social-mobile {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: block;
    width: 10vw;
    margin-top: -13.7vw;
  }

  .jm-title-portfolio {
    width: auto;
    margin-top: 0px;
  }

  .menu-social2 {
    display: none;
  }

  .jm-title {
    width: 100%;
  }

  .footer {
    display: none;
  }

  .cont-message {
    padding-top: 0px;
  }

  .icon-social {
    display: none;
  }

  .jm-cv-cont {
    width: 100%;
    margin-top: 18vw;
    margin-bottom: 0vw;
  }

  .line-cont {
    margin-top: 20px;
  }

  .line-cont.portfolio {
    overflow: visible;
  }

  .jm-logo {
    width: 50px;
    max-width: 100%;
  }

  .menu-button {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    margin-top: 0px;
    margin-right: 0px;
    padding: 20px;
  }

  .menu-button.w--open {
    background-color: hsla(0, 0%, 71.3%, 0);
  }

  .logo-puunukk {
    width: 14vw;
    max-width: none;
  }

  .chapter-cont {
    height: 90vh;
  }

  .close-tab {
    display: none;
  }

  .gradient1 {
    line-height: 13.1vw;
  }

  .active-btn-social {
    height: 126px;
    border-radius: 8px;
  }

  .cont-lottie {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .brand {
    width: auto;
    margin-top: 0px;
  }

  .lottie-animation-2 {
    width: 36px;
    max-width: none;
  }

  .cont-footer {
    height: 80vw;
  }

  .this-h {
    margin-top: 0px;
    margin-left: -89px;
    font-size: 90px;
    line-height: 86px;
  }

  .text-jm1 {
    margin-top: 2vw;
    margin-bottom: 2vw;
    font-size: 18vw;
    line-height: 17vw;
    letter-spacing: -0.3vw;
  }

  .text-jm1.left {
    line-height: 17vw;
  }

  .text-jm1.totally {
    font-size: 29vw;
    line-height: 24vw;
  }

  .text-jm1.please {
    font-size: 29vw;
    line-height: 24vw;
  }

  .text-jm1.getout {
    font-size: 12vw;
  }

  .text-jm1.gradient2 {
    line-height: 13vw;
  }

  .blue-circle1 {
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    width: 140vw;
    height: 140vw;
    max-height: none;
    max-width: none;
    margin-top: -52vw;
    // margin-left: -45.1vw;
    opacity: 1;
  }

  .container {
    padding-top: 20px;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .bold-text {
    font-size: 12vw;
    line-height: 10vw;
  }

  .finally-h {
    font-size: 27px;
  }

  .text-jm-black {
    font-size: 41vw;
    line-height: 41vw;
  }

  .text-jm2 {
    margin-top: 1vw;
    margin-bottom: 1vw;
    font-size: 3.5vw;
    line-height: 4vw;
    letter-spacing: 0.2vw;
  }

  .cont-sticky.cont-left {
    overflow: hidden;
    width: 90vw;
  }

  .gradient2 {
    line-height: 16vw;
  }

  .gradient2.tab {
    line-height: 15vw;
  }

  .cursor-jm {
    display: none;
  }

  .body {
    cursor: auto;
  }

  .puunukk_cv-2 {
    margin-top: 6vw;
    margin-bottom: -20px;
    font-size: 31vw;
    letter-spacing: -11px;
    &.nukk {
      margin-left: 10.2vw;
    }
  }

  .h-footer-2 {
    font-size: 12vw;
    line-height: 11vw;
  }

  .moment-text-2 {
    margin-bottom: 10px;
    font-size: 9vw;
    line-height: 9vw;
    letter-spacing: -0.1vw;
  }

  .tag1-2 {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
  }

  .tag1-2.right {
    display: none;
  }

  .tag1-2.lovely {
    display: none;
    margin-top: 174px;
    margin-left: -21px;
    padding-top: 0px;
  }

  .p-body1-2 {
    font-size: 2vw;
    line-height: 3.5vw;
  }

  .p-body1-2.end {
    width: 100%;
  }

  .cv-cont-anima-2 {
    display: none;
    margin-top: 142px;
    margin-bottom: 10vh;
    padding: 50px 50px 10vh;
  }

  .cv-cont-2 {
    width: 90vw;
    margin-top: 20vw;
    margin-bottom: 10vh;
    padding: 7vw;
  }

  .link-big-social-2 {
    margin-top: 7vw;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 12vw;
    line-height: 11vw;
  }

  .text-end2-2 {
    font-size: 3vw;
    line-height: 4vw;
  }

  .text-user-2 {
    top: 23.3vw;
    margin-right: -34.9vw;
    font-size: 7vw;
    line-height: 8vw;
    letter-spacing: 0.2vw;
  }

  .heading-2 {
    font-size: 3vw;
  }

  .this-h-2 {
    margin-top: 9vw;
    margin-bottom: 12vw;
    margin-left: 0vw;
  }

  .text-jm1-2 {
    margin-top: 2vw;
    margin-bottom: 2vw;
    font-size: 18vw;
    line-height: 17vw;
    letter-spacing: -0.3vw;
  }

  .text-jm1-2.left {
    line-height: 17vw;
  }

  .text-jm1-2.totally {
    font-size: 29vw;
    line-height: 24vw;
  }

  .text-jm1-2.please {
    font-size: 29vw;
    line-height: 24vw;
  }

  .text-jm1-2.getout {
    font-size: 12vw;
  }

  .text-jm1-2.gradient2 {
    line-height: 13vw;
  }

  .finally-h-2 {
    margin-bottom: 19vw;
    font-size: 27px;
  }

  .text-jm-black-2 {
    font-size: 41vw;
    line-height: 41vw;
  }

  .text-jm2-2 {
    margin-top: 1vw;
    margin-bottom: 1vw;
    font-size: 3.5vw;
    line-height: 4vw;
    letter-spacing: 0.2vw;
  }

  .link-email {
    margin-top: 1vh;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 6vw;
  }

  .jm-title-email {
    width: auto;
    margin-top: 95px;
  }

  .real-footer {
    margin-top: 103px;
  }
}