@media screen and (max-width: 767px) {
  .tab2 {
    top: 47.6vh;
    display: none;
  }
  .finally-h-2 {
    margin-bottom: 10vw;
  }
  .puunukk_cv {
    margin-top: 15vw;
    margin-bottom: -6vw;
    margin-left: 0px;
    font-size: 31vw;
    font-size: 31vw;
    line-height: 11vw;
    letter-spacing: -3vw;
    &.nukk {
      margin-left: 28.3vw;
      margin-top: -15vw;
    }
  }

  .h-footer {
    font-size: 15vw;
    line-height: 13vw;
  }

  .moment-text {
    font-size: 10vw;
    line-height: 11vw;
    letter-spacing: -0.3vw;
  }

  .section {
    display: none;
  }

  .tag1 {
    opacity: 0.6;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 2px;
  }

  .tag1.lovely {
    margin-top: 152px;
  }

  .p-body1 {
    opacity: 0.8;
  }

  .p-body1.end {
    width: 100%;
    margin-bottom: 0vh;
    font-size: 5vw;
    line-height: 8vw;
    letter-spacing: 0.2vw;
  }

  .cont-open-menu {
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100vh;
    margin-top: 0px;
    padding-right: 8vw;
    padding-bottom: 0px;
    padding-left: 8vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .lottie-scroll-jm {
    position: absolute;
    display: block;
    width: 220vw;
    height: 100vh;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-align: center;
  }

  .mail-footer {
    display: none;
    font-size: 15vw;
    line-height: 13vw;
  }

  .bg-cont {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .header-tags {
    left: auto;
    top: 0%;
    right: 5vw;
    bottom: auto;
    margin-left: 0px;
  }

  .cv-cont-anima {
    margin-top: 116px;
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 22px;
  }

  .cv-cont {
    display: block;
    margin-top: 116px;
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 22px;
  }

  .link-big-social {
    padding-top: 8vw;
    padding-bottom: 6vw;
    opacity: 0.72;
    color: #99887f;
    font-size: 14vw;
    letter-spacing: -0.8vw;
  }

  .text-end2 {
    font-size: 4.5vw;
    line-height: 6.5vw;
  }

  .icon-jm1 {
    width: 35px;
  }

  .link {
    color: #29292b;
    font-size: 7vw;
    text-decoration: none;
  }

  .noise-end {
    height: 70vh;
  }

  .end {
    display: none;
  }

  .end.noise {
    display: none;
  }

  .separator-lines {
    height: 55vh;
  }

  .jm-title-portfolio.menu3 {
    margin-top: 0px;
  }

  .jm-title-portfolio.menu {
    margin-top: 6.3vw;
  }

  .jm-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-row-gap: 53px;
    -ms-grid-columns: 0.8fr;
    grid-template-columns: 0.8fr;
  }

  .footer {
    z-index: 10;
    display: none;
  }

  .cont-message {
    width: 85vw;
  }

  .jm-cv-cont {
    margin-bottom: 12vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .jm-hologram {
    top: 3%;
  }

  .jm-logo {
    width: 45px;
    margin-top: 0px;
  }

  .menu-button {
    overflow: hidden;
  }

  .chapter-cont {
    height: 80vh;
  }

  .jm-des {
    margin-top: 35px;
  }

  .open-menu-jm {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 450;
    display: block;
    overflow: hidden;
    width: 0vw;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #090a0b;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .active-btn-social {
    height: 108px;
  }

  .html-embed {
    display: none;
  }

  .cont-lottie {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .brand {
    z-index: 500;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading.menu2 {
    margin-top: 3.2vw;
    font-size: 6vw;
    letter-spacing: 0vw;
  }

  .heading.menu {
    margin-top: 0px;
    margin-bottom: 1vw;
    font-size: 4vw;
  }

  .lottie-animation-2 {
    position: relative;
    z-index: 500;
    opacity: 0.72;
  }

  .cont-footer {
    width: 85vw;
    height: 70vh;
  }

  .this-h {
    margin-left: 0px;
    font-size: 12vw;
    line-height: 5.7vh;
    letter-spacing: -0.3vw;
  }

  .text-jm1.letmego {
    position: static;
    display: block;
  }

  .text-jm1.gradient2 {
    line-height: 18vw;
  }

  .blue-circle1 {
    display: block;
    opacity: 1;
  }

  .finally-h {
    margin-top: 16.3vw;
    font-size: 6vw;
  }

  .cont-sticky.cont-left {
    overflow: visible;
  }

  .h-footer-2 {
    font-size: 15vw;
    line-height: 13vw;
  }

  .moment-text-2 {
    font-size: 10vw;
    line-height: 11vw;
    letter-spacing: -0.3vw;
  }

  .tag1-2 {
    opacity: 0.6;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 2px;
  }

  .tag1-2.lovely {
    margin-top: 152px;
  }

  .p-body1-2 {
    opacity: 0.8;
    font-size: 3vw;
    line-height: 5vw;
  }

  .p-body1-2.end {
    width: 100%;
    margin-bottom: 0vh;
    font-family: Epilogue, sans-serif;
    font-size: 5vw;
    line-height: 8vw;
    letter-spacing: 0.2vw;
  }

  .mail-footer-2 {
    display: none;
    font-size: 15vw;
    line-height: 13vw;
  }

  .cv-cont-anima-2 {
    margin-top: 116px;
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 22px;
  }

  .cv-cont-2 {
    display: block;
  }

  .link-big-social-2 {
    opacity: 0.72;
    color: #99887f;
    letter-spacing: -0.8vw;
  }

  .text-end2-2 {
    font-size: 4.5vw;
    line-height: 6.5vw;
  }

  .noise-end-2 {
    height: 70vh;
  }

  .heading-2.menu2 {
    margin-top: 3.2vw;
    font-size: 6vw;
    letter-spacing: 0vw;
  }

  .heading-2.menu {
    margin-top: 0px;
    margin-bottom: 1vw;
    font-size: 4vw;
  }

  .text-jm1-2.letmego {
    position: static;
    display: block;
  }

  .text-jm1-2.gradient2 {
    line-height: 18vw;
  }

  .link-email {
    opacity: 0.72;
    color: #99887f;
  }

  .jm-title-email.menu3 {
    margin-top: 0px;
  }

  .jm-title-email.menu {
    margin-top: 6.3vw;
  }

  .real-footer {
    margin-top: 152px;
    margin-bottom: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}